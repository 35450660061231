import {
  observable,
  observableArray,
  ObservableArray,
} from 'knockout-decorators'

import { EventState } from '../event'
import { Translations, Analytics } from '~/utils'
import { Offer, OfferAvailability } from '@tixa/schema'

import { Interactor } from './interactor'
import { ReservationState } from '../reservation'
import { SeatmapValidatedCoupon } from '~/store/SeatmapValidatedCoupon'
import { SpecialOfferType, CouponOfferError } from '@tixa/api-client'
import { pageState } from '~/store/page'

type OfferValidator = (offers: Offer[]) => [string, boolean][]
type Coupons = string | string[]
type Request = {
  event: string
  coupon?: string
  coupons?: Coupons
  type: string
  code?: string
}

interface SpecialOfferRequest {
  eventID: string
  request: Request
  type: SpecialOfferType
  categoryFilter: string
  extraOfferProperties?: [string, unknown][]
  isValid: OfferValidator
}

export class SpecialOfferInteractor extends Interactor<EventState> {
  private analytics = Analytics.getInstance()

  @observableArray public couponChips = [] as ObservableArray<string>
  @observable public codeApplied = ''
  @observableArray public invalidCoupons = [] as ObservableArray<string>
  @observableArray public validCoupons = [] as ObservableArray<string>

  constructor(state: EventState) {
    super(state)
  }

  public async getCouponOffers(event: string, coupon: string, type: string) {
    const ev = this.state.eventConfigById(event)
    if (ev.hasSeatmap) {
      const stateObj = this.state.reservationStates[ev.seatMapID]
      await this.passCouponsToSeatmap([coupon], stateObj)
      return
    }
    try {
      const offers = await this.getSpecialOffers({
        eventID: event.toString(),
        request: { event, coupon, type },
        type: SpecialOfferType.COUPON,
        categoryFilter: 'coupon',
        isValid: (offerResults: Offer[]) => [
          [
            coupon,
            offerResults.filter(
              (offer) => offer.appliedCoupon && offer.appliedCoupon === coupon
            ).length > 0,
          ],
        ],
      })
      if (offers.length === 0) {
        this.state.globalError = Translations.wrongcoupon
      } else {
        this.analytics.appliedCoupon()
      }
    } catch (e) {
      if (e instanceof CouponOfferError) {
        this.state.globalError = Translations.couponused
      } else {
        this.state.globalError = e.message
      }
    }
  }

  public async getMultipleCouponOffers(
    event: string,
    coupons: string[],
    type: string
  ): Promise<void> {
    const ev = this.state.eventConfigById(event)
    if (ev.hasSeatmap) {
      const stateObj = this.state.reservationStates[ev.seatMapID]
      await this.passCouponsToSeatmap(coupons, stateObj)
      return
    }
    const offers = await this.getSpecialOffers({
      eventID: event.toString(),
      request: { event, coupons, type },
      type: SpecialOfferType.COUPON,
      categoryFilter: 'coupon',
      isValid: (offerResults: Offer[]) => {
        const offerCoupons = offerResults.map(
          (offer) => offer.appliedCoupon || ''
        )
        return coupons.map((coupon) => [coupon, offerCoupons.includes(coupon)])
      },
    })
    if (offers.length === 0) {
      this.state.globalError = Translations.wrongcoupon
    } else {
      this.analytics.appliedCoupon()
    }
  }

  private async passCouponsToSeatmap(
    coupons: string | string[],
    stateObj: ReservationState
  ) {
    try {
      const seatmap = stateObj.seatmap
      const results: SeatmapValidatedCoupon[] = await seatmap.setCoupon(coupons)
      this.clearValidation()
      this.savSeatmapValidation(results)
    } catch (e) {
      this.state.globalError = Translations.wrongcoupon
    }
    return true
  }

  public async getCodeOffers(event: string, code: string): Promise<void> {
    const offers = await this.getSpecialOffers({
      eventID: event.toString(),
      request: { event, code, type: 'code' },
      type: SpecialOfferType.CODE,
      categoryFilter: 'code',
      extraOfferProperties: [['appliedCode', code]],
      isValid: (offerResults: Offer[]) => {
        return [[code, offerResults.length > 0]]
      },
    })
    if (offers.length === 0) {
      this.state.globalError = Translations.wrongcode
    } else {
      this.codeApplied = code
      this.analytics.appliedCode()
    }
  }

  private async getSpecialOffers({
    eventID,
    request,
    type,
    categoryFilter,
    extraOfferProperties,
    isValid,
  }: SpecialOfferRequest) {
    const offers =
      type === SpecialOfferType.COUPON
        ? await pageState.api.specialOffers.getCouponOffers(request)
        : await pageState.api.specialOffers.getCodeOffers(request)

    if (!offers.length) return []

    const processedOffers = this.processSpecialOffers(
      offers,
      eventID,
      categoryFilter,
      extraOfferProperties
    )

    if (processedOffers.length > 0) {
      this.saveValidation(processedOffers, isValid)
      this.replaceOffers(eventID, processedOffers)
    }

    return processedOffers
  }

  private processSpecialOffers(
    offers: Offer[],
    eventID: string,
    category: string,
    extraProps?: [string, unknown][]
  ) {
    let processed = this.filterOfferCategory(offers, category).map((offer) => {
      offer = this.addOfferProperties(offer, extraProps)
      offer = this.addOfferDefaults(offer, eventID)
      return offer
    })
    processed = this.autoAddSingleOffer(offers)
    this.replaceOffers(eventID, processed)
    return processed
  }

  private clearValidation() {
    ;(this.validCoupons as ObservableArray<string>).removeAll()
    ;(this.invalidCoupons as ObservableArray<string>).removeAll()
  }

  private saveValidation(offers: Offer[], isValid: OfferValidator) {
    const couponValidations = isValid(offers)
    this.clearValidation()
    this.validCoupons.push(
      ...couponValidations
        .filter((values) => values[1] === true)
        .map((values) => values[0])
    )
    this.invalidCoupons.push(
      ...couponValidations
        .filter((values) => values[1] === false)
        .map((values) => values[0])
    )
  }

  private savSeatmapValidation(results: SeatmapValidatedCoupon[]) {
    this.invalidCoupons.push(
      ...results
        .filter((result) => !result.isValid)
        .map((result) => result.coupon)
    )
    this.validCoupons.push(
      ...results
        .filter((result) => result.isValid)
        .map((result) => result.coupon)
    )
  }

  private filterOfferCategory(offers: Offer[], accepted: string): Offer[] {
    return offers.filter((offer) => offer.category === accepted)
  }

  private addOfferProperties(
    offer: Offer,
    properties?: [string, unknown][]
  ): Offer {
    if (!properties || properties.length === 0) {
      return offer
    }
    properties.forEach((prop) => {
      offer[prop[0]] = [prop[1]]
    })
    return offer
  }

  private addOfferDefaults(offer: Offer, eventId: string): Offer {
    offer.eventID = eventId
    offer.eligibleQuantity.value = 0
    return offer
  }

  private autoAddSingleOffer(offers: Offer[]): Offer[] {
    if (this.state.isMultiEvent) return offers
    if (offers.length > 1) return offers
    if (
      offers.length === 0 ||
      offers[0].availability !== OfferAvailability.InStock
    )
      return offers

    offers[0].eligibleQuantity.value = 1
    return offers
  }

  private replaceOffers(eventID: string, offers: Offer[]) {
    const oldOfferEventIds = this.state.offers
      .filter((offer) => offer.eventID.toString() === eventID)
      .map((offer) => offer.eventID.toString())

    const remainingOffers = this.state.offers.filter(
      (offer) => !oldOfferEventIds.includes(offer.eventID.toString())
    )

    ;(this.state.offers as ObservableArray<Offer>).removeAll()

    // Replace matching offers
    this.state.offers.push(...remainingOffers, ...offers)
  }
}
