import { pageState } from '~/store/page'

import { AnalyticsEventsHandler } from './types'
import { createFacebookPixelHandler } from './facebook-pixel'
import { createFacebookConversionApiHandler } from './facebook-conversion-api'
import { createGoogleAnalyticsHandler } from './google'

declare const EMBED: boolean

export class Analytics {
  private static instance: AnalyticsEventsHandler

  public static getInstance(): AnalyticsEventsHandler {
    if (!Analytics.instance) {
      // Create the instance
      Analytics.instance = this.createAnalyticsBroadcastProxy(
        createFacebookConversionApiHandler(),
        createFacebookPixelHandler(),
        createGoogleAnalyticsHandler()
      )
      if (!EMBED) {
        window.addEventListener('load', () => {
          const config = pageState.config
          Analytics.instance.init(config)
        })
      }
    }
    return Analytics.instance
  }

  private static createAnalyticsBroadcastProxy = (
    ...handlers: AnalyticsEventsHandler[]
  ): AnalyticsEventsHandler => {
    const proxy = new Proxy(
      {},
      {
        get:
          (target, prop) =>
          async (...args) => {
            // this is the function that will be called
            for (const handler of handlers) {
              if (prop in handler) {
                try {
                  await handler[prop](...args)
                } catch (e) {
                  console.error(e)
                }
              }
            }
          },
      }
    )
    return proxy as AnalyticsEventsHandler
  }
}

export const Tracker = Analytics.getInstance()
