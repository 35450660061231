import { Storage } from '~/utils'

const CART_TIME_STORAGE_INTERVAL = 1000
const CART_TIME_STORAGE_ID = 'cartTime'

const useCartTimeStorageTimer = () => {
  let timer = null
  const start = async () => {
    if (!timer) {
      timer = setInterval(async () => await store(), CART_TIME_STORAGE_INTERVAL)
      await store()
    }
  }
  const stop = async () => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    await remove()
  }
  const store = async () => {
    if (timer !== null) {
      await Storage.storeAsync(
        CART_TIME_STORAGE_ID,
        Date.now().toString(),
        true
      )
    }
  }

  const remove = async () => {
    await Storage.removeFromStoreAsync(CART_TIME_STORAGE_ID, true)
  }

  return {
    start,
    stop,
    store,
    remove,
  }
}

export { useCartTimeStorageTimer }
