import { PaymentChecker } from './payment-checker'

import { useOrderStore } from '../cart/state/store-order'

export class PaymentPrechecker {
  public static async check() {
    const orderStore = useOrderStore()
    if (await orderStore.hasOrderData()) {
      const checker = new PaymentChecker()
      const order = await orderStore.getOrderData()
      await checker.checkPayment(order.orderId)
    }
  }
}
