import { Offer, PageConfig } from '@tixa/schema'
import { AnalyticsEventsHandler } from './types'

import { initFacebookPixel } from './initFacebookPixel.js'

const convertOffers = (offers: Offer[]): fb.Pixel.ViewContentParameters => {
  return {
    content_ids: offers.map((offer) => offer.sku),
    content_type: 'product',
    contents: offers.map((item) => {
      return {
        id: item.sku,
        quantity: item.eligibleQuantity.value,
      }
    }),
    currency: offers.length > 0 ? offers[0].priceCurrency : '',
    value: offers
      .map((offer) => offer.price)
      .reduce((prev, curr) => prev + curr, 0),
  }
}

const createFacebookPixelHandler = (): AnalyticsEventsHandler => {
  let isActive = false

  const send = (event: string, parameters?: fb.Pixel.ViewContentParameters) => {
    if (isActive) {
      fbq('track', event, parameters)
    }
  }

  return {
    init: async (config: PageConfig) => {
      if (config.fbPixel) {
        initFacebookPixel(config.fbPixel)
        try {
          if (!config.fbEventRelayEndpoint) {
            isActive = true
            // Send all server-sent events to Facebook
            if (undefined !== config.fbTrack) {
              config.fbTrack
                .filter((item) => item.eventName !== undefined)
                .forEach((item) => {
                  send(item.eventName, item.customData)
                })
            }
          }
        } catch {
          // Couldn't load Facebook
        }
      }
    },
    searched: (term: string) => send('Search'),
    viewed: (offers: Offer[]) => send('ViewContent', convertOffers(offers)),
    addedToCart: (
      name: string,
      product: string,
      category: string,
      id: string,
      value: number,
      currency: string,
      quantity: number,
      coupon?: string
    ) => {
      send('AddToCart', {
        content_ids: [id.toString()],
        content_name: product,
        content_category: category,
        content_type: 'product',
        currency,
        value,
      })
    },
    checkoutStarted: (cartId: string, offers: Offer[]) =>
      send('InitiateCheckout', convertOffers(offers)),
    paymentInfoAdded: (offers: Offer[]) =>
      send('AddPaymentInfo', convertOffers(offers)),
    purchased: (id: string, offers: Offer[]) => {
      const fbObj = convertOffers(offers) as fb.Pixel.PurchaseParameters
      fbObj.order_id = id
      send('Purchase', fbObj)
    },
    registered: (value: number, currency: string) =>
      send('CompleteRegistration', { value, currency }),
  }
}

export { createFacebookPixelHandler }
