import { observable } from 'knockout-decorators'
import axios from 'axios'

import { FeatureToggle } from '@tixa/schema'
import type { PageConfig } from '@tixa/schema'
import { getPageData } from '~/utils/ajax'
import {
  TixaApi,
  createTixaApi,
  AuthenticationMethod,
  CartApiLanguage,
  CartEndpoint,
  OrderEndpoint,
  WaitingRoomApiLanguage,
  WaitingRoomEndpoint,
  LanguageEndpoint,
} from '@tixa/api-client'
import { getPageCsrfToken, getPageBaseUrl } from '~/utils/ajax'
import { Locale } from '~/utils/language'

export class PageState {
  public static get(): PageState {
    if (!PageState.instance) {
      PageState.instance = new PageState()
    }
    return PageState.instance
  }

  private static instance: PageState

  @observable
  public blockAnimations = false

  @observable
  public sliderImage = ''

  private _config: PageConfig
  private _api: TixaApi

  public get config(): PageConfig {
    if (!this._config) {
      try {
        this._config = getPageData<PageConfig>('pageConfig')
      } catch {
        this._config = undefined
      }
    }
    return this._config
  }

  public set config(value: PageConfig) {
    this._config = value
  }

  public get api(): TixaApi {
    if (!this._api) {
      const baseURL = getPageBaseUrl()
      const token = getPageCsrfToken()
      const language = Locale.language === 'hu' ? 'hu-HU' : 'en-US'

      this._api = createTixaApi({
        axios: {
          instance: axios,
          baseURL,
          csrfToken: token,
          language,
        },
        authentication: {
          authMethod: AuthenticationMethod.LEGACY,
        },
        authentication_jwt: {
          authMethod: AuthenticationMethod.JwT,
        },
        cart: {
          preCheckEndpoint: this.hasToggle(
            FeatureToggle.NewWaitingRoomEndpoints
          )
            ? CartEndpoint.NEW
            : CartEndpoint.LEGACY,
          language: this.hasToggle(FeatureToggle.EnglishWaitingRoomEndpoints)
            ? CartApiLanguage.ENGLISH
            : CartApiLanguage.HUNGARIAN,
          cartEndpoint: this.hasToggle(FeatureToggle.NewCartEndpoints)
            ? CartEndpoint.NEW
            : CartEndpoint.LEGACY,
        },
        fbEventRelay: {
          endpoint: this.config?.fbEventRelayEndpoint,
          disabled:
            !this.hasToggle(FeatureToggle.RelayFacebookEvents) ||
            !this.config?.fbEventRelayEndpoint,
        },
        language: {
          languageEndpoint: this.hasToggle(FeatureToggle.NewLanguageEndpoint)
            ? LanguageEndpoint.NEW
            : LanguageEndpoint.LEGACY,
        },
        impression: {
          disabled: this.hasToggle(FeatureToggle.DisableImpressionCalls),
        },
        order: {
          checkPaymentEndpoint: this.hasToggle(
            FeatureToggle.NewWaitingRoomEndpoints
          )
            ? OrderEndpoint.NEW
            : OrderEndpoint.LEGACY,
          orderEndpoint: this.hasToggle(FeatureToggle.NewOrderEndpoints)
            ? OrderEndpoint.NEW
            : OrderEndpoint.LEGACY,
        },
        waitingRoom: {
          endpoint: this.hasToggle(FeatureToggle.NewWaitingRoomEndpoints)
            ? WaitingRoomEndpoint.NEW
            : WaitingRoomEndpoint.LEGACY,
          language: this.hasToggle(FeatureToggle.EnglishWaitingRoomEndpoints)
            ? WaitingRoomApiLanguage.ENGLISH
            : WaitingRoomApiLanguage.HUNGARIAN,
        },
      })
    }
    return this._api
  }

  public hasToggle(toggle: FeatureToggle): boolean {
    if (!this.config) {
      return false
    }
    return this.config?.toggles?.includes(toggle)
  }

  private constructor() {
    //
  }
}

export const pageState = PageState.get()
