import {
  type Family,
  createFamiliesFromImage,
  labToHex,
  labToRgb,
  labToHsl,
} from '@tixahungary/image-palette'
import * as StackBlur from 'stackblur-canvas'
import onresize from '~/utils/onresize'

import { Graphics } from '~/utils/graphics'

import '~/images/default_bg.jpg'
const Default_image = new URL('../images/default_bg.jpg', import.meta.url).href

export enum ImageProcessStep {
  blur = 'blur',
  shadow = 'shadow',
  colorOverlay = 'colorOverlay',
  original = 'original',
}

interface OverlayOptions {
  mode: GlobalCompositeOperation
  target: 'foreground' | 'background'
  color1: {
    family: number
    index: number
  }
  color2: {
    family: number
    index: number
  }
}

interface Overlay {
  mode: GlobalCompositeOperation
  target: 'foreground' | 'background'
  color1: string
  color2: string
}

interface ImageProcessProps {
  originalImage: HTMLImageElement
  canvas: HTMLCanvasElement | OffscreenCanvas
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  colors: Family[]
}

const OVERLAY_MODES: OverlayOptions[] = [
  // light
  {
    mode: 'soft-light',
    target: 'foreground',
    color1: { family: 0, index: 4 },
    color2: { family: 1, index: 7 },
  },
  // gray
  {
    mode: 'multiply',
    target: 'background',
    color1: { family: 0, index: 6 },
    color2: { family: 1, index: 9 },
  },
  // dark
  {
    mode: 'multiply',
    target: 'background',
    color1: { family: 0, index: 6 },
    color2: { family: 1, index: 6 },
  },
]

export class Background {
  public static getInstance(): Background {
    if (!Background.instance) {
      Background.instance = new Background()
    }
    return Background.instance
  }

  private static instance: Background

  private bgImage: HTMLImageElement = document.querySelector(
    'img.background'
  ) as HTMLImageElement
  private processedImages = new Map<string, Blob>()
  private objectUrls = new Map<string, string>()
  private hasOffscreen = typeof OffscreenCanvas === 'function'

  private resizeDispose: () => void = null

  private readonly stepOperations = new Map<
    string,
    (props: ImageProcessProps) => void
  >()
  private readonly defaultOperations = [
    ImageProcessStep.original,
    ImageProcessStep.colorOverlay,
    ImageProcessStep.blur,
    ImageProcessStep.shadow,
  ]

  private constructor() {
    this.stepOperations = new Map(
      Object.entries({
        blur: this.blurBackground,
        colorOverlay: this.colorOverlayBackground,
        original: this.drawOriginalImage,
        shadow: this.drawBackgroundShadow,
      })
    )
  }

  public async addCanvasBackground(
    name: string,
    image: HTMLImageElement,
    steps: ImageProcessStep[] = this.defaultOperations
  ) {
    const colors = await this.getColorSetup(image)
    const canvas = await this.runStepsOnCanvas(image, steps, colors)
    let blob: Blob
    if (this.hasOffscreen && canvas instanceof OffscreenCanvas) {
      blob = await canvas.convertToBlob({
        type: 'image/jpeg',
        quality: 0.9,
      })
    } else if (canvas instanceof HTMLCanvasElement) {
      const dataUrl = canvas.toDataURL('image/jpeg', 0.9)
      canvas.width = 0
      canvas.height = 0
      blob = this.dataURItoBlob(dataUrl)
    }
    this.processedImages.set(name, blob)
  }

  public async setCanvasBackground(src: string): Promise<void> {
    document.body.classList.remove('background-loaded')
    document.body.classList.add('background-loading')
    //await this.waitForBackgroundElement()
    if (!this.objectUrls.has(src)) {
      this.objectUrls.set(
        src,
        URL.createObjectURL(this.processedImages.get(src))
      )
    }

    const wh = window.innerHeight

    if (this.resizeDispose) {
      this.resizeDispose()
    }

    this.resizeDispose = (onresize as any).on(() => {
      const newH = window.innerHeight
      // const diff = wh - newH
      const ratio = Math.max(1, wh / newH)
      this.bgImage.style.transform = `scale(${ratio
        .toString()
        .substring(0, 4)})`
    }).dispose

    /// document.body.style.backgroundImage = `url(${this.objectUrls[src]})`;
    this.bgImage.src = this.objectUrls.get(src)
    document.body.classList.remove('background-loading')
    document.body.classList.add('background-loaded')
  }

  public async setDefaultBackground(): Promise<void> {
    const image: HTMLImageElement = document.createElement('img')
    image.src = Default_image

    await this.addCanvasBackground(Default_image, image, [
      ImageProcessStep.original,
      ImageProcessStep.blur,
    ])
    this.setCanvasBackground(Default_image)
  }

  private getOverlayMode(image: HTMLImageElement, families: Family[]): Overlay {
    const brightness = Graphics.isImageDark(image)
    const brightnessIndex = Math.min(Math.floor(brightness / (255 / 3)), 2)
    const overlayMode = OVERLAY_MODES[brightnessIndex]
    const { color1, color2 } = overlayMode

    const color1Family = families[color1.family]
    const color2Family = families[color2.family]

    const bgBlue = '#0cb586'
    const bgYellow = '#d3e768'

    if (!color1Family || !color1Family.colors[color1.index]) {
      console.error(
        `color1 family or index not found: ${color1.family}, ${color1.index}`
      )
      return {
        ...overlayMode,
        color1: bgYellow,
        color2: bgBlue,
      }
    }

    if (!color2Family || !color2Family.colors[color2.index]) {
      console.error(
        `color2 family or index not found: ${color2.family}, ${color2.index}`
      )
      return {
        ...overlayMode,
        color1: bgYellow,
        color2: bgBlue,
      }
    }

    return {
      ...overlayMode,
      color1: labToHex(
        families[color1.family].colors[color1.index] as [number, number, number]
      ),
      color2: labToHex(
        families[color2.family].colors[color2.index] as [number, number, number]
      ),
    }
  }

  public async getColorSetup(image: HTMLImageElement) {
    const colorFamilies = await createFamiliesFromImage(
      document.createElement('canvas') as HTMLCanvasElement,
      image,
      'lab'
    )

    const keyColorHues = colorFamilies.map((family) => {
      const key = family.keyColor as [number, number, number]
      const hsl = labToHsl(key)
      return hsl[0]
    })
    const sortedKeyColorHues = Graphics.sortHuesForDifference(keyColorHues)
    const sortedKeyHueIndexes = sortedKeyColorHues.map((hue) =>
      keyColorHues.indexOf(hue)
    )
    const sortedColorFamilies = sortedKeyHueIndexes.map(
      (hueIndex) => colorFamilies[hueIndex]
    )
    return sortedColorFamilies
  }

  private blurBackground = async (props: ImageProcessProps) => {
    const { width: w, height: h } = props.canvas
    StackBlur.canvasRGB(props.canvas as HTMLCanvasElement, 0, 0, w, h, 25)
  }

  private colorOverlayBackground = async (props: ImageProcessProps) => {
    const gradient = props.context.createLinearGradient(
      0,
      0,
      props.canvas.width,
      props.canvas.height
    )

    const { target, mode, color1, color2 } = this.getOverlayMode(
      props.originalImage,
      props.colors
    )

    gradient.addColorStop(0, color1)
    gradient.addColorStop(1, color2)

    const { width: w, height: h } = props.canvas
    if (target === 'background') {
      Graphics.fillContext(props.context, mode, 1, gradient, 0, 0, w, h)
    } else {
      const { canvas: newCanvas, context } = this.createCanvas(w, h)
      if (context === null) {
        throw new Error("Couldn't get canvas context")
      }
      // Draw the gradient on the new canvas
      Graphics.fillContext(context, 'copy', 1, gradient, 0, 0, w, h)
      context.globalCompositeOperation = mode
      // Draw the original image on the new canvas with the composite mode
      context.drawImage(props.canvas, 0, 0)
      // Clear the original canvas
      props.context.clearRect(0, 0, w, h)
      // Draw the new canvas on the original canvas
      props.context.drawImage(newCanvas, 0, 0)
    }
  }

  private drawBackgroundShadow = async (props: ImageProcessProps) => {
    const { height: h } = props.canvas
    const grd = props.context.createLinearGradient(0, h / 2, 0, h)
    const shadowColor = labToRgb(
      props.colors[0].colors[10] as [number, number, number]
    )
    grd.addColorStop(0, Graphics.rgbToRgba(shadowColor, 0))
    grd.addColorStop(1, Graphics.rgbToRgba(shadowColor, 100))

    props.context.fillStyle = grd
    props.context.fillRect(0, 0, props.canvas.width, props.canvas.height)
  }

  private drawOriginalImage = async (props: ImageProcessProps) => {
    const { width: w, height: h } = props.canvas
    const { width: imgW, height: imgH } = props.originalImage

    const sizing = Graphics.scaleAndCenter('cover', w, h, imgW, imgH)
    const { left, top, width, height } = sizing

    props.context.globalCompositeOperation = 'color'
    props.context.drawImage(props.originalImage, left, top, width, height)
  }

  private createCanvas(width: number, height: number) {
    const canvas = this.hasOffscreen
      ? new OffscreenCanvas(width, height)
      : document.createElement('canvas')
    if (!this.hasOffscreen) {
      canvas.width = width
      canvas.height = height
    }
    return {
      canvas,
      context: canvas.getContext('2d') as
        | CanvasRenderingContext2D
        | OffscreenCanvasRenderingContext2D,
    }
  }

  private async runStepsOnCanvas(
    image: HTMLImageElement,
    steps: ImageProcessStep[],
    colors: Family[]
  ) {
    const { canvas, context } = this.createCanvas(image.width, image.height)
    const imageProps: ImageProcessProps = {
      canvas,
      context,
      originalImage: image,
      colors,
    }
    await Promise.all(
      steps.map((step) => this.stepOperations.get(step)(imageProps))
    )
    return canvas
  }

  private dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const dw = new DataView(ab)
    for (let i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i))
    }
    return new Blob([ab], {
      type: dataURI.split(',')[0].split(':')[1].split(';')[0],
    })
  }
}
