import { Storage } from '~/utils'

const CART_ID_STORAGE_ID = 'cartID'

const useCartIdStorage = () => {
  const store = async (id: number) => {
    await Storage.storeAsync(CART_ID_STORAGE_ID, String(id), true)
  }
  const remove = async () => {
    await Storage.removeFromStoreAsync(CART_ID_STORAGE_ID, true)
  }

  return {
    store,
    remove,
  }
}

export { useCartIdStorage }
