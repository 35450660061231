import { AnalyticsEventsHandler } from './types'
import { EventItem, Offer, PageConfig } from '@tixa/schema'

import { PageState } from '~/store/page'
import { AuthState } from '~/components/login/utils/state'
import { CartState } from '~/components/cart/state'
import { Authentication } from '../authentication'
import { getPageData } from '../ajax'
import { logger } from '../log'

import {
  sha256,
  getCookie,
  hasCouponApplied,
  hasInCart,
  isFee,
  isShippingOffer,
} from './utils'
import type { StoredOrderData } from '~/components/cart/state/store-order'

const ENABLE_DEBUG = false
const FB_PIXEL_WAIT_LIMIT = 5000

const createCustomData = (
  offers: Offer[] = [],
  inCartOnly = true
): CustomData => {
  ENABLE_DEBUG && logger.debug('createCustomData', offers)
  const event = getPageData<EventItem>('eventData')
  const relevantOffers = inCartOnly ? offers.filter(hasInCart) : offers
  const shippingOffers = relevantOffers.filter(isShippingOffer)
  const offersWithCoupon = relevantOffers.filter(hasCouponApplied)
  const productOffers = relevantOffers.filter(
    (offer) => !isShippingOffer(offer) && !isFee(offer)
  )
  // convert offers to products
  const products: Product[] = productOffers.map((offer) => {
    return {
      id: offer.sku,
      quantity: offer.eligibleQuantity.value,
      item_price: offer.price,
    }
  })
  // create custom data
  const customData: CustomData = {
    content_type: 'product',
    content_category: 'ticket',
    content_name: event && event.name ? event.name : document.title,
    contents: products,
    content_ids: productOffers.map((offer) => offer.sku.toString()),
    value: productOffers.reduce(
      (acc, offer) => acc + offer.price * offer.eligibleQuantity.value,
      0
    ),
    num_items: productOffers
      .reduce((acc, offer) => acc + offer.eligibleQuantity.value, 0)
      .toString(),
    currency: relevantOffers[0]?.priceCurrency || 'HUF',
    delivery_category: shippingOffers.length > 0 ? 'home_delivery' : 'in_store',
    coupon:
      offersWithCoupon.length > 0
        ? offersWithCoupon.map((offer) => offer.appliedCoupon || '').join(',')
        : undefined,
  }
  return customData
}

let cachedEmail = undefined

const getEmail = async (): Promise<string | undefined> => {
  ENABLE_DEBUG && logger.debug('getEmail')
  const authState = AuthState.get()
  const pageState = PageState.get()

  if (authState.email.length > 0) {
    return authState.email
  } else if (pageState.config.isLoggedIn) {
    if (!cachedEmail) {
      const user = await Authentication.getInstance().GetUser()
      cachedEmail = user.person.email
    }
    return cachedEmail
  }
  return undefined
}

const normalizePhone = (phone?: string): string | undefined => {
  if (!phone) {
    return undefined
  }
  const onlyDigits = phone.replace(/[^0-9]/g, '')
  if (onlyDigits.startsWith('06')) {
    return onlyDigits.replace(/^06/, '+36')
  }
  if (onlyDigits.length === 8) {
    return `+36${onlyDigits}`
  }
  return onlyDigits
}

const normalizeName = (
  name?: string
): { first_name: string; last_name: string } | undefined => {
  if (!name) {
    return undefined
  }
  let first = ''
  let last = ''
  if (name.indexOf(' ') > -1) {
    const parts = name.split(' ')
    first = parts[0].trim().toLowerCase()
    last = parts[parts.length - 1].trim().toLowerCase()
  } else {
    first = name
  }
  return { first_name: first, last_name: last }
}

const normalizeString = (str?: string): string | undefined => {
  if (!str) {
    return undefined
  }
  return str.toLowerCase().trim()
}

const getUserDetailsFromStoredOrder = (
  storedOrder?: StoredOrderData
): NormalizedUserData | undefined => {
  if (!storedOrder) {
    return undefined
  }
  return {
    email: normalizeString(storedOrder.email),
    phone: normalizePhone(storedOrder.phone),
    city: normalizeString(storedOrder.city),
    zip: normalizeString(storedOrder.zip),
    ...normalizeName(storedOrder.name),
  }
}

const getUserDetailsFromCartState = (): NormalizedUserData | undefined => {
  const cartState = CartState.get()
  if (cartState.content) {
    return {
      email: normalizeString(cartState.formData?.person?.email),
      phone: normalizePhone(cartState.formData?.person?.telephone),
      city: normalizeString(
        cartState.formData?.person?.address.addressLocality
      ),
      zip: normalizeString(cartState.formData?.person?.address.postalCode),
      ...normalizeName(cartState.formData?.person?.name),
    }
  }
}

const hashFbUserDetails = async (
  userDetails?: NormalizedUserData
): Promise<HashedFbUserData> => {
  if (!userDetails) {
    return {}
  }
  const { email, phone, city, zip, first_name, last_name } = userDetails
  return {
    em: email ? [await sha256(email)] : undefined,
    ph: phone ? [await sha256(phone)] : undefined,
    ct: city ? [await sha256(city)] : undefined,
    zp: zip ? [await sha256(zip)] : undefined,
    fn: first_name ? [await sha256(first_name)] : undefined,
    ln: last_name ? [await sha256(last_name)] : undefined,
  }
}

const createFacebookConversionApiHandler = (): AnalyticsEventsHandler => {
  let pixelId: string = undefined
  let hasInitialized = false
  let user_ip: string = undefined
  let has_pixel = false
  let existingWaitPromise: Promise<void> = undefined
  let existingInitPromise: Promise<void> = undefined

  const started = Date.now()

  const getUserData = async (storedOrder?: StoredOrderData) => {
    ENABLE_DEBUG && logger.debug('getUserData')
    await waitForInit()

    let hashedUserDetails: HashedFbUserData = {}

    const userDetails = storedOrder
      ? getUserDetailsFromStoredOrder(storedOrder)
      : getUserDetailsFromCartState()

    if (userDetails) {
      hashedUserDetails = await hashFbUserDetails(userDetails)
    } else {
      const email = await getEmail()
      hashedUserDetails = await hashFbUserDetails({
        email,
      })
    }

    return {
      client_ip_address: user_ip,
      client_user_agent: navigator.userAgent,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
      ...hashedUserDetails,
    }
  }

  const createEvent = async (
    eventName: EventName,
    customData: CustomData,
    storedOrder?: StoredOrderData
  ): Promise<Event> => {
    ENABLE_DEBUG && logger.debug('createEvent', eventName, customData)
    return {
      event_name: eventName,
      event_time: Math.floor(Date.now() / 1000),
      action_source: 'website',
      event_source_url: window.location.href,
      user_data: await getUserData(storedOrder),
      custom_data: customData,
      opt_out: false,
    }
  }

  const waitForPixel = async () => {
    if (!has_pixel) {
      if (existingWaitPromise) {
        return existingWaitPromise
      }
      existingWaitPromise = new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (getCookie('_fbp')) {
            ENABLE_DEBUG && logger.debug('pixel loaded')
            clearInterval(interval)
            has_pixel = true
            resolve()
          } else {
            const now = Date.now()
            if (now - started > FB_PIXEL_WAIT_LIMIT) {
              ENABLE_DEBUG && logger.debug('giving up waiting for pixel')
              clearInterval(interval)
              resolve()
            }
          }
        }, 100)
      })
      return existingWaitPromise
    }
  }

  const waitForInit = async () => {
    if (!hasInitialized) {
      if (existingInitPromise) {
        return existingInitPromise
      }
      ENABLE_DEBUG && logger.debug('waiting for init')
      existingInitPromise = new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (hasInitialized) {
            ENABLE_DEBUG && logger.debug('init done')
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
      return existingInitPromise
    }
  }

  const send = async (...events: Event[]) => {
    const request: Request = {
      data: events,
    }
    await waitForInit()
    if (pixelId) {
      if (!has_pixel) {
        ENABLE_DEBUG && logger.debug('waiting for pixel')
        await waitForPixel()
      }
      ENABLE_DEBUG && logger.debug('sending', JSON.stringify(request, null, 2))
      await PageState.get().api.fbEventRelay.submit(request, pixelId)
    }
  }

  const handler: AnalyticsEventsHandler = {
    async init(config: PageConfig) {
      ENABLE_DEBUG && logger.debug('init', config)
      if (config.fbPixel) {
        pixelId = config.fbPixel
      }
      if (config.user_ip) {
        user_ip = config.user_ip
      }
      hasInitialized = true
      if (config.fbTrack) {
        const tasks = config.fbTrack.map(async (eventData) => {
          const event = await createEvent(
            eventData.eventName as EventName,
            eventData.customData as CustomData
          )
          return event
        })
        const events = await Promise.all(tasks)
        await send(...events)
      }
    },
    searched: async (term: string) => {
      ENABLE_DEBUG && logger.debug('searched', term)
      const event = await createEvent('Search', { search_string: term })
      await send(event)
    },
    viewed: async (offers: Offer[]) => {
      ENABLE_DEBUG && logger.debug('viewed', offers)
      const event = await createEvent(
        'ViewContent',
        createCustomData(offers, false)
      )
      await send(event)
    },
    addedToCart: async (
      product: string,
      id: string,
      value: number,
      currency: string,
      quantity: number,
      coupon?: string
    ) => {
      const event = await createEvent('AddToCart', {
        content_type: 'product',
        content_category: 'ticket',
        content_ids: [id.toString()],
        content_name: product,
        coupon,
        value,
        currency,
        num_items: quantity.toString(),
        contents: [
          {
            id,
            quantity,
            item_price: value,
          },
        ],
      })
      await send(event)
    },
    checkoutStarted: async (cartId: string, offers: Offer[]) => {
      const cartOffers = CartState.get().content.cart
      const event = await createEvent('InitiateCheckout', {
        order_id: CartState.get().content.id.toString(),
        ...createCustomData(cartOffers),
      })
      await send(event)
    },
    paymentInfoAdded: async (offers: Offer[], data?: StoredOrderData) => {
      if (data) {
        const event = await createEvent(
          'AddPaymentInfo',
          {
            order_id: data.cartId.toString(),
            ...createCustomData(data.offers),
          },
          data
        )
        await send(event)
      }
    },
    purchased: async (id, offers, cartId, data) => {
      if (data) {
        const event = await createEvent(
          'Purchase',
          {
            order_id: data.cartId.toString(),
            ...createCustomData(data.offers),
            content_name: data.event,
          },
          data
        )
        await send(event)
      }
    },
    registered: async (value: number, currency: string) => {
      const event = await createEvent('CompleteRegistration', {
        value,
        currency,
      })
      await send(event)
    },
  }

  return handler
}

export type {
  Request,
  Event,
  UserData,
  CustomData,
  EventName,
  ActionSource,
  Product,
}

export { createFacebookConversionApiHandler }

/// Types

type Product = {
  /**
   * The ID of the product.
   */
  id: string
  /**
   * Quanity in the cart.
   */
  quantity: number
  /**
   * The price of the product.
   */
  item_price: number
  /**
   * Delivery category.
   */
  delivery_category?: 'curbside' | 'in_store' | 'home_delivery'
}

type CustomData = {
  /**
   * A numeric value associated with this event. This could be a monetary value or a value in some other metric.
   * Required for purchase events.
   */
  value?: number
  /**
   * The currency of the value.
   * Required for purchase events.
   */
  currency?: string
  /**
   * The content IDs associated with the event, such as product SKUs for items in an AddToCart event.
   * If content_type is a product, then your content IDs must be an array with a single string value.
   * Otherwise, this array can contain any number of string values.
   */
  content_ids?: string[]
  /**
   * The name of the content associated with the event, such as a product name for items in an AddToCart event.
   */
  content_name?: string
  /**
   * Use product if the keys you send represent products. Sent keys could be content_ids or contents.
   * Use product_group if the keys you send in content_ids represent product groups.
   * Product groups are used to distinguish products that are identical but have variations such as color, material, size or pattern.
   */
  content_type?: 'product' | 'product_group'
  /**
   * The category of the content associated with the event, such as a product category for items in an AddToCart event.
   */
  content_category?: string
  /**
   * The contents of the cart.
   */
  contents?: Product[]
  /***
   * Optional for purchase events.
   */
  delivery_category?: 'curbside' | 'in_store' | 'home_delivery'
  /**
   * The number of items in the cart.
   * Use only with InitiateCheckout events.
   */
  num_items?: string
  /**
   * The order ID for this transaction as a string.
   */
  order_id?: string
  /**
   * The predicted lifetime value of a conversion event.
   */
  predicted_ltv?: number
  /**
   * A search query made by a user.
   * Use only with Search events.
   */
  search_string?: string
  /**
   * The status of the registration.
   * Use only with CompleteRegistration events.
   */
  status?: 'registered'
  /**
   * CUSTOM PROPERTY
   */
  coupon?: string
}

type UserData = {
  /**
   * IP address
   */
  client_ip_address: string
  /**
   * User agent
   */
  client_user_agent: string
  /**
   * sh256 hash of email
   */
  em?: string[]
  /**
   * sh256 hash of phone
   */
  ph?: string[]
  /**
   * _fbc browser cookie
   */
  fbc?: string
  /**
   * _fbp browser cookie
   */
  fbp?: string
}

type EventName =
  | 'Purchase'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'AddPaymentInfo'
  | 'CompleteRegistration'
  | 'Search'
  | 'ViewContent'

type ActionSource =
  | 'email'
  | 'website'
  | 'app'
  | 'phone_call'
  | 'chat'
  | 'physical_store'
  | 'system_generated'
  | 'other'

type Event = {
  event_name: EventName
  event_time: number
  user_data: UserData
  event_id?: string
  event_source_url?: string
  action_source?: ActionSource
  custom_data?: CustomData
  opt_out: boolean
}

type Request = {
  data: Event[]
}

type NormalizedUserData = {
  first_name?: string
  last_name?: string
  email?: string
  phone?: string
  city?: string
  zip?: string
}

type HashedFbUserData = {
  em?: string[]
  ph?: string[]
  ct?: string[]
  zp?: string[]
  fn?: string[]
  ln?: string[]
}
