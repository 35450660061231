import type { Offer } from '@tixa/schema'

import { Storage } from '~/utils'

const STORAGE_ID = 'pendingOrderData'

export type StoredOrderData = {
  orderId: string
  cartId: string
  event: string
  offers: Offer[]
  name: string
  city: string
  zip: string
  email: string
  phone: string
}

const useOrderStore = () => {
  const storeOrderData = async (data: StoredOrderData) => {
    const trackedOffers = data.offers.filter(
      (item) =>
        item.eligibleQuantity.value > 0 &&
        !item['@id'].toString().startsWith('-')
    )
    await Storage.storeAsync(
      STORAGE_ID,
      JSON.stringify({
        ...data,
        offers: trackedOffers,
      })
    )
  }

  const hasOrderData = async () => {
    return await Storage.hasInStoreAsync(STORAGE_ID)
  }

  const getOrderData = async () => {
    const data = await Storage.getFromStoreAsync(STORAGE_ID)
    return JSON.parse(data) as StoredOrderData
  }

  const clear = async () => {
    await Storage.removeFromStoreAsync(STORAGE_ID)
  }

  return {
    clear,
    hasOrderData,
    storeOrderData,
    getOrderData,
  }
}

export { useOrderStore }
