import type { UserDetails } from '@tixa/schema'
import { Storage } from '~/utils'

const CART_FORM_STORAGE_ID = 'form'

const useCartDataStore = () => {
  const store = async (data: UserDetails) => {
    await Storage.storeAsync(CART_FORM_STORAGE_ID, JSON.stringify(data))
  }
  return { store }
}

export { useCartDataStore }
