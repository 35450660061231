import { crossTalk } from './cross-talk'

declare const EMBED: boolean
declare const tixaOrigin: string

export class Storage {
  public static store(key: string, value: string, local?: boolean): void {
    try {
      local
        ? localStorage.setItem(key, value)
        : sessionStorage.setItem(key, value)
    } catch (error) {
      document.cookie = `${key}=${value}`
    }
  }
  public static storeCookie(key: string, value: string, path?: string): void {
    document.cookie = `${key}=${value}; path=/${path || ''}`
  }
  public static storeAsync(
    key: string,
    value: string,
    local?: boolean
  ): Promise<void> {
    if (EMBED && document.location.origin === tixaOrigin) {
      return crossTalk<void>('setStore', { key, value })
    } else {
      return new Promise<void>((resolve) => {
        resolve(Storage.store(key, value, local))
      })
    }
  }

  public static hasInStore(key: string, local?: boolean): boolean {
    try {
      return (
        null !=
        (local ? localStorage.getItem(key) : sessionStorage.getItem(key))
      )
    } catch (error) {
      return (
        document.cookie.split(';').filter((item) => item.includes(key + '='))
          .length > 0
      )
    }
  }
  public static hasInStoreAsync(
    key: string,
    local?: boolean
  ): Promise<boolean> {
    if (EMBED && document.location.origin === tixaOrigin) {
      return crossTalk<boolean>('hasInStore', { key })
    } else {
      return new Promise<boolean>((resolve) => {
        resolve(Storage.hasInStore(key, local))
      })
    }
  }

  public static getFromStore(key: string, local?: boolean): string {
    try {
      return local ? localStorage.getItem(key) : sessionStorage.getItem(key)
    } catch (error) {
      const escape = (s: string) => s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1')
      const match = document.cookie.match(
        RegExp('(?:^|;\\s*)' + escape(key) + '=([^;]*)')
      )
      return match ? match[1] : null
    }
  }
  public static getFromStoreAsync(
    key: string,
    local?: boolean
  ): Promise<string> {
    if (EMBED && document.location.origin === tixaOrigin) {
      return crossTalk<string>('getFromStore', { key })
    } else {
      return new Promise<string>((resolve) => {
        resolve(Storage.getFromStore(key, local))
      })
    }
  }

  public static removeFromStore(key: string, local?: boolean): void {
    try {
      local ? localStorage.removeItem(key) : sessionStorage.removeItem(key)
    } catch (error) {
      document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
  }
  public static removeFromStoreAsync(
    key: string,
    local?: boolean
  ): Promise<void> {
    if (EMBED && document.location.origin === tixaOrigin) {
      return crossTalk<void>('removeFromStore', { key })
    } else {
      return new Promise<void>((resolve) => {
        resolve(Storage.removeFromStore(key, local))
      })
    }
  }
}
