import { Login } from '~/components/login/login'
import { pageState } from '~/store/page'
import { UserDetails } from '@tixa/schema'

export class Authentication {
  public static getInstance(): Authentication {
    if (!Authentication.instance) {
      Authentication.instance = new Authentication()
    }
    return Authentication.instance
  }

  private static instance: Authentication
  private isLoggedIn = false
  private login: Login | null = null

  private constructor() {
    try {
      const config = pageState.config
      this.isLoggedIn = config.isLoggedIn
    } catch (error) {
      this.isLoggedIn = false
    }
  }

  public setLoggedIn(value: boolean) {
    this.isLoggedIn = value
  }

  public LoggedIn(): boolean {
    return this.isLoggedIn
  }

  public async Login(callback: () => void): Promise<void> {
    await this.loadLoginComponent()
    if (this.login) {
      this.login.startLogin(callback)
    }
  }

  public async Recover(callback: () => void): Promise<void> {
    await this.loadLoginComponent()
    if (this.login) {
      this.login.startRecovery(callback)
    }
  }

  public async ShowProfileDiag(): Promise<void> {
    await this.loadLoginComponent()
    if (this.login) {
      return this.login.showProfile()
    }
  }

  public async GetUser(): Promise<UserDetails> {
    return await pageState.api.user.GetUser()
  }

  public HideDiag(): void {
    if (this.login) {
      return this.login.hide()
    }
  }

  private async loadLoginComponent() {
    if (null == this.login) {
      const login = await import('../components/login/login')
      this.login = new login.Login()
    }
  }
}
