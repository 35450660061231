import { setGlobalDateI18n } from 'fecha'
import { i18config } from '@tixa/translations'

import { pageState } from '~/store/page'

export class Language {
  public static getInstance(): Language {
    if (!Language.instance) {
      Language.instance = new Language()
    }
    return Language.instance
  }

  public static formatCurrency(amount: number, currency: string): string {
    return currency === 'HUF'
      ? amount.toLocaleString('hu-HU') + ' Ft'
      : '€' + amount.toLocaleString('en-UK')
  }

  public static formatQuantity(amount: number): string {
    return amount + (this.getInstance().language === 'hu' ? 'db' : 'pcs')
  }

  private static instance: Language

  public language = ''
  public translations: any = {}

  private DEFAULT_LANGUAGE = 'en'
  private supportedLanguages = Object.keys(i18config)
  private isInit = false

  private constructor() {
    this.setLocale(
      this.getPageConfigLanguage() ||
        this.getDocumentElementLanguage() ||
        this.getNavigatorLanguage() ||
        this.DEFAULT_LANGUAGE
    )
  }

  public setLocale(language: string): void {
    let toSet = language
    if (!this.supportedLanguages.includes(language)) {
      toSet = this.DEFAULT_LANGUAGE
    }
    this.language = toSet
    this.translations = i18config[toSet].translation
    if (language === 'hu') {
      setGlobalDateI18n({
        // D is the day of the month, function returns something like...  3rd or 11th
        DoFn: (D: number) => {
          const tens = Math.floor(D / 10) * 10
          const rems = D - tens
          let ending = ''
          if (rems === 0) {
            ending = tens === 1 ? '-én' : '-án'
          }
          if (rems === 1) {
            ending = tens === 0 ? '-jén' : '-én'
          } else if (rems === 2) {
            ending = tens === 0 ? '-án' : '-én'
          } else if (rems === 3 || rems === 6 || rems === 8) {
            ending = '-án'
          } else if (rems === 4 || rems === 5 || rems === 7 || rems === 9) {
            ending = '-én'
          }
          return D.toString() + ending
        },
        amPm: ['de', 'du'],
        dayNames: [
          'vasárnap',
          'hétfő',
          'kedd',
          'szerda',
          'csütörtök',
          'péntek',
          'szombat',
        ],
        dayNamesShort: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Szo'],

        monthNames: [
          'január',
          'február',
          'március',
          'április',
          'május',
          'június',
          'július',
          'augusztus',
          'szeptember',
          'október',
          'november',
          'december',
        ],
        monthNamesShort: [
          'jan',
          'feb',
          'már',
          'ápr',
          'máj',
          'jún',
          'júl',
          'aug',
          'szept',
          'okt',
          'nov',
          'dec',
        ],
      })
    }
    this.isInit = true
    document.documentElement.lang = toSet
  }

  public get(key: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const check = () =>
        this.isInit ? resolve(this.translations[key]) : setTimeout(check, 10)
      check()
    })
  }

  private getPageConfigLanguage() {
    try {
      const config = pageState.config
      if (!this.supportedLanguages.includes(config.language)) {
        return undefined
      }
      return config.language
    } catch (error) {
      return undefined
    }
  }

  private getNavigatorLanguage() {
    return this.supportedLanguages.find((lang) =>
      navigator.language.startsWith(lang)
    )
  }

  private getDocumentElementLanguage() {
    return this.supportedLanguages.find((lang) =>
      document.documentElement.lang.startsWith(lang)
    )
  }
}

export const Locale = Language.getInstance()
export const Translations = Locale.translations
