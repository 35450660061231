declare const DEVELOPMENT: boolean
const ISTEST = window && window.location.host.indexOf('teszt') === 0

const shouldEmitLogs = DEVELOPMENT || ISTEST

const logger = {
  debug: (...data: any[]) => shouldEmitLogs && console.debug(...data),
  log: (...data: any[]) => shouldEmitLogs && console.log(...data),
  warm: (...data: any[]) => shouldEmitLogs && console.warn(...data),
  error: (...data: any[]) => shouldEmitLogs && console.error(...data),
}
export { logger }
