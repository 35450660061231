export const EventSaleStatus = {
    soon: "soon",
    codeonly: "codeonly",
    onsale: "onsale",
    soldout: "soldout",
    closed: "closed",
    unavailable: "unavailable",
} as const;

export type EventSaleStatus = typeof EventSaleStatus[keyof typeof EventSaleStatus];
