import * as ko from 'knockout'
import '../utils/ko-extenders'

import { Graphics } from '../utils/graphics'

import '../styles/bootstrap.scss'
import './page.less'

import '../components/page-alert/page-alert'
import '../components/page-footer/page-footer'
import '../components/page-header/page-header'
import { PaymentPrechecker } from '../components/payment-checker/payment-prechecker'
import { ErrorReporter } from '../utils/error-reporting'

import '@fontsource/nunito/variable.css'

export abstract class Page {
  constructor() {
    Graphics.init()
    ErrorReporter.init()
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        ko.applyBindings(this)
      }
    }
    window.addEventListener('load', async () => {
      await PaymentPrechecker.check()
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      }
    })
  }
}
