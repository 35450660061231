import { component } from 'knockout-decorators'
import { marked } from 'marked'

import { pageState } from '~/store/page'
import type { PageAlertConfig } from '@tixa/schema'

import './page-alert.less'
import source from './page-alert.html?raw'

function renderMarkdown(text) {
  const renderer = new marked.Renderer()
  const linkRenderer = renderer.link
  renderer.link = (href, title, textToRender) => {
    const htmlresult = linkRenderer.call(renderer, href, title, textToRender)
    return htmlresult.replace(/^<a /, '<a target="_blank" rel="nofollow" ')
  }
  return marked.parse(text, { renderer })
}

@component('page-alert', source)
export class PageAlert {
  private config = pageState.config

  private isTest = document && document.location.host.indexOf('teszt') === 0
  private alert = this.config.alert || null

  private get alertsAsArray(): PageAlertConfig[] {
    if (this.alert === null) {
      return []
    }
    if (typeof this.alert === 'object' && Array.isArray(this.alert)) {
      return this.alert
    }
    if (typeof this.alert === 'object' && !Array.isArray(this.alert)) {
      return [this.alert]
    }
    if (typeof this.alert === 'string') {
      return [{ type: 'danger', message: this.alert }]
    }
  }

  private get alerts(): (PageAlertConfig & {
    renderText: string
    renderType: string
  })[] {
    return this.alertsAsArray.map((alert) => {
      return {
        ...alert,
        renderText: renderMarkdown(alert.message),
        renderType: `alert-${alert.type}`,
      }
    })
  }
}
