const optimizedResize = (function () {
  let callbacks = [],
    running = false

  let addedFirst = false

  // fired on resize event
  function resize() {
    if (!running) {
      running = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks)
      } else {
        setTimeout(runCallbacks, 66)
      }
    }
  }

  // run the actual callbacks
  function runCallbacks() {
    callbacks.forEach(function (callback) {
      callback()
    })

    running = false
  }

  // adds callback to loop
  function addCallback(callback) {
    if (callback) {
      callbacks.push(callback)
    }
  }

  function removeCallback(callback) {
    if (callback) {
      const callbackIndex = callbacks.indexOf(callback)
      if (callbackIndex > -1) callbacks.splice(callbackIndex, 1)
    }
  }

  return {
    /** public method to add additional callback */
    on(callback) {
      if (!addedFirst) {
        window.addEventListener('resize', resize)
        addedFirst = true
      }
      addCallback(callback)
      return { dispose: () => removeCallback(callback) }
    },
    /** public method to remove an existing callback */
    off: (callback) => {
      removeCallback(callback)
    },
  }
})()

export default optimizedResize
