import {
  observable,
  computed,
  subscribe,
  observableArray,
} from 'knockout-decorators'

import type {
  CartResponse,
  Offer,
  Person,
  Organization,
  OrderRequest,
  OrderResponse,
  CartRequest,
  UserDetails,
} from '@tixa/schema'
import {
  CartStatus,
  ShippingMode,
  PaymentMode,
  OrderStatus,
} from '@tixa/schema'

import * as Yup from 'yup'

import { Analytics, Authentication, Language } from '~/utils'
import { EventState } from '~/store/event'
import { PageState } from '~/store/page'

import { useCartTimeStorageTimer } from './store-cart-time'
import { useCartExpiryCheckTimer } from './expiry-checker'
import { useCartIdStorage } from './store-cart-id'
import { StoredOrderData, useOrderStore } from './store-order'
import { useCartDataStore } from './store-cart-data'

declare const EMBED: boolean

const SHIPPING_OFFER_ID = '-1001'

export class CartState {
  private Locale = Language.getInstance()
  public static get(): CartState {
    if (!CartState.instance) CartState.instance = new CartState()
    return CartState.instance
  }

  private static instance: CartState
  private pageState = PageState.get()
  private analytics = Analytics.getInstance()

  @observable public isInvoiceDifferent = false

  private cartTimeStorageTimer = useCartTimeStorageTimer()
  private cartExpiryTimer = useCartExpiryCheckTimer(
    () => this.isInQueue,
    async () => {
      this.analytics.checkoutExpired()
      await this.clear()
    },
    (remaining) => {
      this.remainingCartTime = remaining
    }
  )
  private cartIdStorage = useCartIdStorage()
  private orderStore = useOrderStore()
  private cartStore = useCartDataStore()

  @observable
  public isPrechecking = false

  @observable
  public isRequestOutdated = false

  @observable
  public isSubmittingOrder = false

  @observable
  public request?: CartRequest = null

  @observable
  public content?: CartResponse = null

  @observable
  public order?: OrderResponse = null

  @observable
  public hasUser = Authentication.getInstance().LoggedIn()

  // Cart form
  public formElement: HTMLFormElement = null

  public message(key: string): string {
    return this.Locale.translations[key]
  }

  private validationSchema = Yup.object().shape({
    email1: Yup.string()
      .email(() => this.message('cartFormEmailFormat'))
      .required(() => this.message('cartFormEmail')),
    name: Yup.string()
      .min(3, () => this.message('cartFormInputLength'))
      .required(() => this.message('cartFormName')),
    phone: Yup.string()
      .min(3, () => this.message('cartFormInputLength'))
      .required(() => this.message('cartFormPhone')),
    zip: Yup.string()
      .min(3, () => this.message('cartFormInputLength'))
      .required(() => this.message('cartFormZip')),
    city: Yup.string()
      .min(3, () => this.message('cartFormInputLength'))
      .required(() => this.message('cartFormCity')),
    address: Yup.string()
      .min(3, () => this.message('cartFormInputLength'))
      .required(() => this.message('cartFormAddress')),
    invoiceName: Yup.string().when(
      '$isInvoiceDifferent',
      (isInvoiceDifferent, schema) => {
        if (isInvoiceDifferent[0]) {
          return schema
            .min(3, () => this.message('cartFormInputLength'))
            .required(() => this.message('cartFormInvoiceName'))
        }

        return schema
      }
    ),
    invoiceVat: Yup.string().when(
      '$isInvoiceDifferent',
      (isInvoiceDifferent, schema) => {
        if (isInvoiceDifferent[0]) {
          return schema
            .min(3, () => this.message('cartFormInputLength'))
            .required(() => this.message('cartFormInvoiceVat'))
        }
        return schema
      }
    ),
    invoiceZip: Yup.string().when(
      '$isInvoiceDifferent',
      (isInvoiceDifferent, schema) => {
        if (isInvoiceDifferent[0]) {
          return schema
            .min(3, () => this.message('cartFormInputLength'))
            .required(() => this.message('cartFormInvoiceZip'))
        }
        return schema
      }
    ),
    invoiceCity: Yup.string().when(
      '$isInvoiceDifferent',
      (isInvoiceDifferent, schema) => {
        if (isInvoiceDifferent[0]) {
          return schema
            .min(3, () => this.message('cartFormInputLength'))
            .required(() => this.message('cartFormInvoiceCity'))
        }
        return schema
      }
    ),
    invoiceAddress: Yup.string().when(
      '$isInvoiceDifferent',
      (isInvoiceDifferent, schema) => {
        if (isInvoiceDifferent[0]) {
          return schema
            .min(3, () => this.message('cartFormInputLength'))
            .required(() => this.message('cartFormInvoiceAddress'))
        }
        return schema
      }
    ),
    termAcceptCheckbox: Yup.boolean().oneOf([true], () =>
      this.message('cartFormTermAcceptCheckbox')
    ),
  })

  private getValidationSchema() {
    const schema = this.validationSchema

    const dynamicFieldsSchema = this.content?.fields.reduce(
      (shape, field, index) => {
        let fieldSchema: Yup.AnySchema = Yup.string()

        if (field.type === 'text') {
          fieldSchema = Yup.string()
        } else if (field.type === 'date') {
          fieldSchema = Yup.date()
            .nullable()
            .transform((value, originalValue) =>
              originalValue === '' ? null : value
            )
        } else if (field.type === 'check') {
          fieldSchema = Yup.boolean().oneOf(
            [true],
            this.message('requiredCheckbox')
          )
        }

        if (field.optional === false) {
          fieldSchema = fieldSchema.required(this.message('requiredCartField'))
        }

        shape[`field${index}-${field.id}`] = fieldSchema

        return shape
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      },
      {} as { [key: string]: Yup.AnySchema }
    )

    if (this.content?.extraTerms) {
      this.content.extraTerms.forEach((term, index) => {
        dynamicFieldsSchema[`extra-term-${index}`] = Yup.boolean().oneOf(
          [true],
          this.message('requiredCheckbox')
        )
      })
    }

    return schema.shape(dynamicFieldsSchema)
  }

  @observable
  public chosenOptionalOffers: string[] = []

  @observable
  public shippingMode: ShippingMode = ShippingMode.eticket

  @observable
  public paymentMode: PaymentMode = PaymentMode.card

  @observable selectedShippingSku = ''

  @observable
  public person?: Person = null

  @observable
  public organization?: Organization = null

  @observable
  public formValidated = false

  @observable
  public formIsValid = false

  // Terms
  @observable
  public termsAccepted = false

  @observableArray
  public extraTermsAccepted: string[] = []

  // Expiration
  @observable
  public expirationLimit = -1

  @observable
  public elapsedTime = -1

  @computed
  public get offers(): Offer[] {
    if (this.content && this.content.cart) {
      return this.content.cart
    }
    return []
  }

  @computed
  public get shippingOffers(): Offer[] {
    return this.offers.filter(
      (offer) => offer['@id'].toString() === SHIPPING_OFFER_ID
    )
  }

  @computed
  public get isInQueue(): boolean {
    return (
      this.content?.status === CartStatus.wait ||
      this.content?.status === CartStatus.queue
    )
  }

  @computed
  public get queue() {
    if (!this.isInQueue) {
      return null
    }
    return {
      cartId: this.content.id,
      finished: (cart: CartResponse) => {
        console.debug('Queue finished')
        this.update(cart)
      },
      error: (cart: CartResponse) => {
        console.debug('Queue error')
        this.update(cart)
      },
    }
  }

  @computed
  public get error() {
    return this.hasError(this.content, this.order, this.hasCartExpired)
  }

  public hasError(
    cart?: CartResponse,
    order?: OrderResponse,
    hasExpired?: boolean
  ): string | null {
    const cartErrorStates: CartStatus[] = [
      CartStatus.couponused,
      CartStatus.error,
      CartStatus.onecart,
      CartStatus.soldout,
    ]
    const orderErrorStates: OrderStatus[] = [
      'nemmehet',
      'kodlejart',
      'kuponlejart',
      'kuponhasznalt',
    ]

    if (hasExpired) {
      return 'timesup'
    }

    if (cartErrorStates.includes(cart?.status)) {
      return cart.status
    }

    if (orderErrorStates.includes(order?.status)) {
      return order.status
    }

    return null
  }

  @observable remainingCartTime = 0

  @computed
  public get hasCartExpired(): boolean {
    return this.remainingCartTime < 0
  }

  @computed
  public get formData(): UserDetails {
    return {
      person: this.person,
      invoice: this.organization,
    }
  }

  get orderRequest(): OrderRequest {
    if (!this.content || !this.content.id) {
      return null
    }

    const cart = [
      ...this.content.cart.filter((offer) => offer.eligibleQuantity.value > 0),
      ...this.content.cart
        .filter((offer) => offer.sku === this.selectedShippingSku)
        .map((offer) => {
          return {
            ...offer,
            eligibleQuantity: {
              ...offer.eligibleQuantity,
              value: 1,
            },
          }
        }),
      ...this.content.cart
        .filter((offer) => this.chosenOptionalOffers.includes(offer['@id']))
        .map((offer) => {
          return {
            ...offer,
            eligibleQuantity: {
              ...offer.eligibleQuantity,
              value: 1,
            },
          }
        }),
    ]

    const fields = this.content.fields.filter(
      (field) => field.value !== undefined
    )

    return {
      id: this.content.id,
      cart,
      payment: this.paymentMode,
      person: this.person,
      invoiceAddress: this.organization?.address,
      invoiceName: this.organization?.name,
      invoiceVat: this.organization?.vatID,
      code: this.content.code,
      coupon: this.content.coupon,
      cartCoupon: this.content.cartCoupon,
      fields,
      message: this.content.message,
      password: this.content.password,
      requestUrl: window['embedOrigin'] || document.location.href,
    }
  }

  constructor() {
    subscribe<UserDetails>(() => this.formData, this.cartStore.store)
  }

  public async createRequest(request: CartRequest) {
    this.request = request
    await this.runRequest()
  }

  public async runRequest() {
    if (this.request) {
      this.isRequestOutdated = false
      let result: CartResponse
      try {
        result = await this.pageState.api.cart.requestCart(this.request)
      } catch (e) {
        console.debug(e)
      }
      if (result) {
        this.update(result)
      }
    }
  }

  public async deleteCart() {
    if (this.content?.id) {
      try {
        await this.pageState.api.waitingRoom.delete(this.content.id)
      } catch (e) {
        console.debug(e)
      }
    }
  }

  public async clear() {
    await this.cartIdStorage.remove()
    await this.cartTimeStorageTimer.remove()
    if (!this.order) {
      this.analytics.cartCleared(this.content.id)
      await this.deleteCart()
    }
    console.debug('Clearing cart')
    this.update(null)
    this.hasUser = Authentication.getInstance().LoggedIn()
    this.formValidated = false
    this.isRequestOutdated = true
    EventState.get().cartRequest = null
  }

  public formLoaded = (e) => {
    this.formElement = e
  }

  // This function takes care of updating content received from the server
  private async update(response: CartResponse) {
    // When response is false-ish, clear state
    if (!response) {
      await this.cartTimeStorageTimer.stop()
      await this.cartExpiryTimer.stop()
      this.content = null
      return
    }

    const sendAnalyticsEvent =
      !this.content && typeof response?.id !== 'undefined'

    // Merge response with existing reponse
    this.content = {
      ...(this.content || {}),
      ...response,
    }

    if (!this.content.payment || this.content.payment.length === 0) {
      this.paymentMode = PaymentMode.free
    }
    if (
      this.content.payment &&
      this.content.payment.length > 0 &&
      !this.content.payment.includes(this.paymentMode)
    ) {
      this.paymentMode = this.content.payment[0]
    }

    // If the response has a cart ID, store it
    // and start checking timeout
    if (
      this.content?.id &&
      this.content.status &&
      (this.content.status === CartStatus.cart ||
        this.content.status === CartStatus.wait)
    ) {
      await this.cartIdStorage.store(this.content?.id)
      await this.cartTimeStorageTimer.start()
      this.remainingCartTime = await this.cartExpiryTimer.start(
        this.content?.expiry
      )
      if (sendAnalyticsEvent) {
        this.analytics.checkoutStarted(
          response.id.toString(),
          response.cart || []
        )
      }
    } else {
      await this.cartIdStorage.remove()
      await this.cartTimeStorageTimer.stop()
      await this.cartExpiryTimer.stop()
    }
  }

  // CART COUPON

  public async applyCoupon(coupon: string) {
    try {
      const response = await this.pageState.api.cart.addCartCoupon(
        this.content?.id,
        coupon
      )
      if ('error' in response) {
        return response.error
      }
      this.update(response)
      return true
    } catch (e) {
      return e.message
    }
  }

  public async removeCoupon(coupon: string) {
    try {
      const response = await this.pageState.api.cart.removeCartCoupon(
        this.content?.id,
        coupon
      )
      if ('error' in response) {
        return response.error
      }
      this.update(response)
      return true
    } catch (e) {
      return e.message
    }
  }

  // SUBMIT ORDER
  public async submit(data, e?: Event) {
    if (e) e.preventDefault()
    if (this.isSubmittingOrder) return

    this.isSubmittingOrder = true

    const formData: { [key: string]: string | boolean } = {}

    const inputs = data.srcElement.querySelectorAll('input')
    formData['termAcceptCheckbox'] = this.termsAccepted

    inputs.forEach((input) => {
      if (input.type === 'checkbox') {
        return
      }
      formData[input.id] = input.value
      if (this.content?.fields) {
        this.content.fields.forEach((field, index) => {
          if (field.optional === false && field.type === 'check') {
            formData[`field${index}-${field.id}`] = field.value
          }
        })
      }
      if (this.content?.extraTerms) {
        this.content?.extraTerms.forEach((term, index) => {
          formData[`extra-term-${index}`] = this.extraTermsAccepted.includes(
            `extra-term-${index}`
          )
        })
      }
    })

    const validationResult = await this.validateCart(formData)
    if (!validationResult) {
      this.isSubmittingOrder = false
      return
    }

    const backToQueue =
      typeof validationResult === 'object' &&
      validationResult.status === CartStatus.wait

    if (backToQueue) {
      this.update({
        status: validationResult.status,
        id: 0,
        cart: [],
      })
    } else {
      await this.submitOrder()
    }
    this.isSubmittingOrder = false
  }

  private async validateCart(formValues) {
    this.formValidated = true

    try {
      const validationSchema = this.getValidationSchema()
      await validationSchema.validate(formValues, {
        abortEarly: false,
        context: {
          isInvoiceDifferent: this.isInvoiceDifferent,
        },
      })

      return await this.pageState.api.waitingRoom.checkQueue(this.content.id)
    } catch (err) {
      const firstError =
        err.inner[0].path === 'termAcceptCheckbox' &&
        err.inner.some((error: Yup.ValidationError) =>
          error.path.includes('field')
        )
          ? err.inner[1]
          : err.inner[0]

      err.inner.forEach((error: Yup.ValidationError) => {
        const inputId = error.path

        const errorMessageElement = this.formElement.querySelector(
          `[data-for="${inputId}"]`
        ) as HTMLElement
        if (errorMessageElement) {
          errorMessageElement.textContent = error.message
          errorMessageElement.classList.add('visible')
          const inputElement = this.formElement.querySelector(`#${inputId}`)
          if (inputElement) {
            inputElement.classList.add('is-invalid')
            const focusElement = this.formElement.querySelector(
              `[data-for="${firstError.path}"]`
            ) as HTMLElement
            focusElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      })

      this.analytics.formInvalid()
      return
    }
  }

  private async submitOrder() {
    try {
      this.order = await this.pageState.api.order.submitOrder(this.orderRequest)

      const eventState = EventState.get()

      if (this.order.status === OrderStatus.success) {
        const orderData: StoredOrderData = {
          orderId: this.order.id,
          cartId: this.content.id.toString(), // Convert the number to a string
          event: eventState.event.name,
          name: this.formData.person.name,
          city: this.formData.person.address.addressLocality,
          zip: this.formData.person.address.postalCode,
          email: this.formData.person.email,
          phone: this.formData.person.telephone,
          offers: this.content.cart,
        }

        await this.orderStore.storeOrderData(orderData)

        this.analytics.paymentInfoAdded(this.content.cart, orderData)
        this.analytics.paymentStarted()
        this.injectPaymentForm()
      }
    } catch (error) {
      /* TODO: HANDLE ERRORS  */
    }
  }

  private injectPaymentForm() {
    if (!this.order?.paymentForm) {
      return
    }

    if (EMBED) {
      window.parent.postMessage(
        { action: 'submitPaymentForm', form: this.order?.paymentForm },
        '*'
      )
      return
    }

    // Create container for payment form
    const formContainer = document.createElement('div')
    formContainer.setAttribute('hidden', 'true')
    formContainer.innerHTML = this.order.paymentForm
    document.body.appendChild(formContainer)

    // Get created form and submit it
    const formElements = formContainer.getElementsByTagName('form')
    if (formElements.length > 0) {
      const formElement = formElements[0]

      // We're leaving the webiste here
      formElement.submit()
    }
  }
}
