import type { Organization, Person, UserDetails } from '@tixa/schema'
import { observable } from 'knockout-decorators'

export class AuthState {
  public static get(): AuthState {
    if (!AuthState.instance) {
      AuthState.instance = new AuthState()
    }
    return AuthState.instance
  }
  private static instance: AuthState

  @observable public isLoading = false

  @observable public email = ''
  @observable public password = ''
  @observable public password2 = ''
  @observable public token = ''

  @observable public appRecoveryKey = ''
  @observable public userRecoveryKey = ''

  @observable public user?: UserDetails = null
}

export const authState = AuthState.get()
