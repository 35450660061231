const u = {
  success: "mehet",
  failure: "nemmehet",
  couponExpired: "kuponlejart",
  codeExpired: "kodlejart",
  error: "error",
  invalid: "invalid"
}, i = {
  success: "success",
  failure: "fail",
  couponExpired: "couponexpired",
  codeExpired: "codeexpired",
  error: "error",
  invalid: "invalid"
}, y = {
  success: "authorized",
  unknown: "unauthorized",
  unlinked: "identity_provider_unlinked"
};
var k = /* @__PURE__ */ ((e) => (e[e.LEGACY = 0] = "LEGACY", e[e.JwT = 1] = "JwT", e))(k || {});
const p = {
  0: {
    login: "user/login",
    logout: "user/logout",
    register: "user/register",
    requestRecovery: "user/requestRecovery",
    recovery: "user/recovery"
  },
  1: {
    login: "api/User-login",
    logout: "api/User-logout",
    // unchanged
    register: "user/register",
    requestRecovery: "user/requestRecovery",
    recovery: "user/recovery"
  }
}, g = (e, r) => {
  const t = p[r.authMethod], n = async (c) => e({
    method: "POST",
    url: t.login,
    data: c
  }), a = async (c) => await e({
    method: "POST",
    url: t.login,
    data: c
  }), s = async (c) => {
    const o = await n({
      username: c.email,
      password: c.password
    });
    return o.message ? (console.log(`Couldn't log in: ${o.message}`), {
      email: c.email,
      status: y.unknown
    }) : {
      email: c.email,
      status: y.success
    };
  };
  return {
    login: r.authMethod == 0 ? a : s,
    logout: async (c) => await e({ method: "POST", url: t.logout, data: c }),
    register: async (c) => await e({
      method: "POST",
      url: t.register,
      data: c
    }),
    requestRecovery: async (c) => await e({
      method: "POST",
      url: t.requestRecovery,
      data: c
    }),
    verifyRecovery: async (c) => await e({
      method: "POST",
      url: t.recovery,
      data: c
    })
  };
};
var E = /* @__PURE__ */ ((e) => (e[e.ENGLISH = 0] = "ENGLISH", e[e.HUNGARIAN = 1] = "HUNGARIAN", e))(E || {}), v = /* @__PURE__ */ ((e) => (e[e.LEGACY = 0] = "LEGACY", e[e.NEW = 1] = "NEW", e))(v || {});
const f = {
  0: "/preCheck",
  1: "api/Waitingroom-preCheck"
}, T = {
  0: "/cart",
  1: "api/Cart-cart"
}, P = {
  0: (e, r, t) => [
    `ticketTypeId=${e}`,
    `&offerId=${r}`,
    `&quantity=${t}`
  ],
  1: (e, r, t) => [
    `jegytipusid=${r}`,
    `&arfejlodesid=${e}`,
    `&darabszam=${t}`
  ]
}, C = {
  0: (e, r, t) => ({
    ticketTypeId: e,
    offerId: r,
    quantity: t
  }),
  1: (e, r, t) => ({
    jegytipusid: r,
    arfejlodesId: e,
    darabszam: t
  })
}, O = (e) => {
  switch (e) {
    case u.success:
      return i.success;
    case u.error:
      return i.error;
    case u.codeExpired:
      return i.codeExpired;
    case u.couponExpired:
      return i.couponExpired;
    case u.failure:
      return i.failure;
    case u.invalid:
      return i.invalid;
  }
}, S = (e, r) => {
  const t = T[r.cartEndpoint];
  return {
    requestCart: async (n) => await e({
      method: "POST",
      url: t,
      data: n
    }),
    preCheck: async (n, a, s, c, o) => {
      const d = P[r.language](n, a, s);
      c && d.push(`&coupon=${c}`), o && d.push(`&code=${o}`), r.csrfToken && d.push(`&_token=${r.csrfToken}`);
      const h = r.preCheckEndpoint === 1 ? C[r.language](n, a, s) : {}, m = r.preCheckEndpoint === 1 ? f[
        1
        /* NEW */
      ] : `${f[
        0
        /* LEGACY */
      ]}?${d}`, l = r.language === 0 ? await e({
        method: "POST",
        url: m,
        data: h
      }) : await e({
        method: "POST",
        url: m,
        data: h
      });
      if ("status" in l)
        return l;
      if ("statusz" in l)
        return {
          status: O(l.statusz),
          reason: l.miert
        };
    },
    addCartCoupon: async (n, a) => await e({
      method: "POST",
      url: `${t}Coupon`,
      data: { id: n, coupon: a }
    }),
    removeCartCoupon: async (n, a) => await e({
      method: "POST",
      url: `${t}CouponRemove`,
      data: { id: n, coupon: a }
    })
  };
}, U = (e, r) => ({
  loadOffers: async (t, n = "hu") => await e({
    method: "GET",
    url: `/embed?event=${t}&lang=${n}`
  })
}), A = (e, r) => ({
  submit: async (t, n) => {
    r.disabled || await e({
      method: "POST",
      url: `${r.endpoint}?p_id=${n}`,
      data: t
    });
  }
}), $ = (e, r) => ({
  submit: async (t) => {
    r.disabled || await e({
      method: "GET",
      url: "/impression",
      data: t
    });
  }
});
var G = /* @__PURE__ */ ((e) => (e[e.LEGACY = 0] = "LEGACY", e[e.NEW = 1] = "NEW", e))(G || {});
const w = {
  0: "/lang",
  1: "api/Language-change"
}, N = (e, r) => ({
  changeLanguage: async (t) => {
    if (r.languageEndpoint === 1) {
      const n = {
        hu: "hu-HU",
        en: "en-US"
      };
      await e({
        method: "POST",
        url: w[r.languageEndpoint],
        data: { lang: t },
        headers: { "Accept-Language": n[t] }
      });
    } else
      window !== void 0 && (window.location.href = `${w[r.languageEndpoint]}/${t}`);
  }
});
var R = /* @__PURE__ */ ((e) => (e[e.LEGACY = 0] = "LEGACY", e[e.NEW = 1] = "NEW", e))(R || {});
const b = {
  0: "/checkPayment",
  1: "api/Waitingroom-checkPayment"
}, x = {
  0: "/order",
  1: "api/Cart-order"
}, L = (e, r) => {
  const t = b[r.checkPaymentEndpoint], n = x[r.orderEndpoint];
  return {
    submitOrder: async (a) => await e({
      method: "POST",
      url: n,
      data: a
    }),
    checkPayment: async (a) => await e({
      method: "POST",
      url: t,
      data: { id: a }
    }),
    getDownloadAllUrl: (a) => `/jegy_letoltes.php?rendelesid=${a.id}&jelszo=${a.key}&hash=${a.hash}`,
    getDownloadUrl: (a, s, c = "PDF") => `/letoltes?formatum=${c}&rendeles=${a.id}&jelszo=${a.key}&vonalkod=${s.barcode}`,
    getInvoiceUrl: (a) => `/getInvoice?${a}`
  };
}, q = (e, r) => {
  const t = "/checkOrder", n = "/checkRefund", a = "/confirmRefund", s = "/submitRefund";
  return {
    check: async (c, o) => await e({
      method: "POST",
      url: t,
      data: { email: c, orderId: o }
    }),
    checkRefund: async (c) => await e({
      method: "POST",
      url: n,
      data: c
    }),
    confirm: async (c, o) => await e({
      method: "POST",
      url: a,
      data: {
        orderId: c,
        confirmationCode: o
      }
    }),
    submit: async (c) => await e({
      method: "POST",
      url: s,
      data: c
    })
  };
}, I = (e, r) => ({
  search: async (t, n) => await e({
    method: "POST",
    url: "/search",
    data: { query: t },
    signal: n.signal
  })
});
var j = /* @__PURE__ */ ((e) => (e[e.COUPON = 0] = "COUPON", e[e.CODE = 1] = "CODE", e))(j || {});
class M extends Error {
}
const Y = (e, r) => {
  const t = "/couponOffers", n = "/codeOffers";
  return {
    getCouponOffers: async (a) => {
      const s = await e({
        method: "POST",
        url: t,
        data: a
      });
      if ("status" in s)
        throw new M("couponused");
      return s.itemListElement;
    },
    getCodeOffers: async (a) => (await e({
      method: "POST",
      url: n,
      data: a
    })).itemListElement
  };
}, z = (e, r) => {
  const t = "/user", n = "/user/balance", a = (s) => `/tickets?after=${s}`;
  return {
    getBalance: async () => await e({
      method: "GET",
      url: n
    }),
    getTickets: async (s) => await e({
      method: "GET",
      url: a(s)
    }),
    GetUser: async () => await e({
      method: "GET",
      url: t
    })
  };
};
var H = /* @__PURE__ */ ((e) => (e[e.ENGLISH = 0] = "ENGLISH", e[e.HUNGARIAN = 1] = "HUNGARIAN", e))(H || {}), D = /* @__PURE__ */ ((e) => (e[e.LEGACY = 0] = "LEGACY", e[e.NEW = 1] = "NEW", e))(D || {});
const _ = {
  1: {
    checkQueue: (e) => ({ parancs: "lekeres", kosarid: e }),
    acceptAlternateCart: (e) => ({ parancs: "alternativ-elfogadva", request: e }),
    identification: (e, r) => ({ parancs: "azonositas", kosarid: e, email: r }),
    delete: (e) => ({ parancs: "torles", kosarid: e })
  },
  0: {
    checkQueue: (e) => ({ operation: "check", cartId: e }),
    acceptAlternateCart: (e) => ({ operation: "accept-alternate", request: e }),
    identification: (e, r) => ({ operation: "identification", cartId: e, email: r }),
    delete: (e) => ({ operation: "delete", cartId: e })
  }
}, W = {
  0: "/waitingRoom",
  1: "api/Waitingroom-waitingroom"
}, Q = (e, r) => {
  const t = W[r.endpoint], n = _[r.language];
  return {
    checkQueue: async (a) => {
      const s = n.checkQueue(a);
      return await e({
        method: "POST",
        url: t,
        data: s
      });
    },
    acceptAlternateCart: async (a) => {
      const s = n.acceptAlternateCart(a);
      return await e({
        method: "POST",
        url: t,
        data: s
      });
    },
    identification: async (a, s) => {
      const c = n.identification(a, s);
      return await e({
        method: "POST",
        url: t,
        data: c
      });
    },
    delete: async (a) => {
      const s = n.delete(a);
      return await e({
        method: "POST",
        url: t,
        data: s
      });
    }
  };
};
function F(e, r, t, n) {
  return e.create({
    baseURL: r,
    headers: {
      "X-CSRF-TOKEN": t,
      "Accept-Language": n
    }
  });
}
async function B(e, r) {
  return (await e.request(r)).data;
}
function J(e) {
  return async (r) => await B(e, r);
}
const X = (e) => {
  const r = F(
    e.axios.instance,
    e.axios.baseURL,
    e.axios.csrfToken,
    e.axios.language
  ), t = J(r);
  return {
    authentication: g(t, e.authentication),
    authentication_jwt: g(
      t,
      e.authentication_jwt
    ),
    cart: S(t, {
      ...e.cart,
      csrfToken: e.axios.csrfToken
    }),
    embed: U(t),
    fbEventRelay: A(t, e.fbEventRelay),
    impression: $(t, e.impression),
    language: N(t, e.language),
    order: L(t, e.order),
    refund: q(t),
    search: I(t),
    specialOffers: Y(t),
    user: z(t),
    waitingRoom: Q(t, e.waitingRoom)
  };
};
export {
  k as AuthenticationMethod,
  E as CartApiLanguage,
  v as CartEndpoint,
  M as CouponOfferError,
  G as LanguageEndpoint,
  R as OrderEndpoint,
  j as SpecialOfferType,
  H as WaitingRoomApiLanguage,
  D as WaitingRoomEndpoint,
  J as createAdapter,
  g as createAuthenticationApi,
  S as createCartApi,
  U as createEmbedApi,
  A as createFbEventRelayApi,
  $ as createImpressionApi,
  N as createLanguageApi,
  F as createNetwork,
  L as createOrderApi,
  q as createRefundApi,
  B as createRequest,
  Y as createSpecialOffersApi,
  X as createTixaApi,
  z as createUserApi,
  Q as createWaitingRoomApi
};
